import React, { ReactNode, useEffect, useState } from 'react';
import { Dialog, Alert, Button, FormInput, Form } from 'foundation';

import capitaliseFirstLetter from 'utils/capitalise-first-letter';
import './confirm-modal.css';

interface Props {
  open: boolean;
  onConfirm: (event: React.MouseEvent) => void;
  onCancel: (event: React.MouseEvent | React.KeyboardEvent) => void;
  title: ReactNode;
  content: ReactNode;
  action: ConfirmAction;
  confirmText: string;
  banner?: ReactNode;
  loading?: boolean;
  buttonTextLoading?: string;
  primary?: boolean;
  negative?: boolean;
  error?: string;
  errorHeader?: string;
}

export enum ConfirmAction {
  DESTROY = 'destroy',
  DELETE = 'delete',
  RESTORE = 'restore',
  TRANSFER = 'transfer',
  UNLINK = 'unlink',
  TEAR_DOWN = 'tear_down',
}

const ConfirmModal = ({
  open,
  onConfirm,
  onCancel,
  loading,
  title,
  content,
  action,
  buttonTextLoading,
  confirmText,
  banner = <></>,
  negative = false,
  error = null,
  errorHeader = null,
}: Props) => {
  const [confirmation, setConfirmation] = useState('');

  const onConfirmationTextChange = e => setConfirmation(e.target.value);

  const testId = action.toLowerCase().replaceAll('_', '-');
  const buttonText = action
    .split('_')
    .map(word => capitaliseFirstLetter(word))
    .join(' ');
  const handleConfirm = e => {
    if (confirmation === confirmText) {
      onConfirm(e);
    }
  };

  useEffect(() => {
    if (open) {
      setConfirmation('');
    }
  }, [open]);

  return (
    <Dialog
      size="small"
      modalProps={{ 'data-testid': 'confirm-modal' }}
      open={open}
      onClose={onCancel}
    >
      <Dialog.Header>{title}</Dialog.Header>
      <Dialog.Subtitle>{content}</Dialog.Subtitle>
      <Dialog.Content>
        <Form onSubmit={handleConfirm}>
          <FormInput
            label="Are you sure?"
            data-testid={`${testId}-confirmation`}
            onChange={onConfirmationTextChange}
            placeholder={`Type "${confirmText}" to confirm`}
            autoComplete="off"
            className="confirm"
            autoFocus
            fluid
          />

          {error && (
            <Alert
              data-testid="confirm-modal-error-message"
              type="danger"
              title={errorHeader}
              description={error}
              className="tw-mt-2"
            />
          )}
        </Form>
        <div className="tw-mt-4">{banner}</div>
      </Dialog.Content>
      <Dialog.Actions>
        <Button
          onClick={onCancel}
          disabled={loading}
          data-testid={`${testId}-cancel-button`}
          fill="outlined"
          color="neutral"
        >
          Cancel
        </Button>
        <Button
          color={negative ? 'danger' : 'primary'}
          disabled={loading || confirmation !== confirmText}
          type="submit"
          onClick={handleConfirm}
          loading={loading}
          data-testid={`${testId}-confirm-button`}
        >
          {loading ? buttonTextLoading : buttonText}
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};

export default ConfirmModal;
