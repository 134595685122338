import React, { useEffect, useState } from 'react';
import {
  Alert,
  CopyTextToClipBoardButton,
  Divider,
  IconButton,
  LoadingSpinner,
  Switch,
} from 'components/foundation';
import './summary.css';
import OrganizationResources, { useOrganizationQuery } from 'remote/resources/organizations';
import { usePermissions } from 'store';
import { Action } from 'types/user';
import { OrganizationCapabilityKeys } from 'types/organization';
import { ConfirmMfaModal } from './confirm-mfa-modal';

interface ItemProps {
  title: React.ReactNode;
  description?: string | any;
  action: React.ReactNode;
  height?: string;
}

const PreferenceItem = ({ title, description, action, height = '24' }: ItemProps) => {
  return (
    <li className={`preference-item tw-flex tw-items-center tw-p-4 tw-h-${height}`}>
      <div className="n-body-large tw-flex tw-flex-col tw-gap-1">
        <div className="tw-text-palette-neutral-text-default tw-truncate">{title}</div>
        <div className="tw-text-palette-neutral-text-weak">{description}</div>
      </div>
      <div className="tw-grow tw-mr-4 tw-flex tw-flex-row-reverse">{action}</div>
    </li>
  );
};

interface SummaryPageProps {
  organizationId: string;
}

const SummaryPage = ({ organizationId }: SummaryPageProps) => {
  const { allow } = usePermissions();
  const { data: organization, isLoading, isError, refetch } = useOrganizationQuery(organizationId);
  const userCanEditOrgSettings = allow(Action.UPDATE, `organizations/${organizationId}`);

  //Using state for more responsive UI
  const [copilotEnabled, setCopilotEnabled] = useState(false);
  const [appMFARequired, setAppMFARequired] = useState(null);
  const [mutationError, setMutationError] = useState(null);
  const [showConfirmMfaRequiredModal, setShowConfirmMfaRequiredModal] = useState<boolean>(false);

  const handeCopilotToggle = async ({ target: { checked } }) => {
    setCopilotEnabled(checked);
    setMutationError(null);
    try {
      await OrganizationResources.update(organization.id, { copilotEnabled: checked });
    } catch (e) {
      setMutationError(e);
      setCopilotEnabled(!checked);
    }
    await refetch();
  };
  const handleAppMfaToggle = () => {
    setMutationError(null);
    OrganizationResources.setMFA(organization.id, !appMFARequired)
      .then(() => {
        setAppMFARequired(!appMFARequired);
      })
      .catch(err => {
        setMutationError(err);
      })
      .finally(() => {
        setShowConfirmMfaRequiredModal(false);
        refetch();
      });
  };

  const onToggleMfaChange = () => {
    setShowConfirmMfaRequiredModal(true);
  };

  useEffect(() => {
    if (organization) {
      setCopilotEnabled(organization?.copilotEnabled);
      setAppMFARequired(organization.appMFAEnabled);
    }
  }, [organization]);

  if (isLoading) {
    return <LoadingSpinner data-testid="loading-spinner" />;
  }

  if (isError) {
    return (
      <Alert
        description="Something went wrong. Please try again later"
        type="danger"
        className="tw-mt-2"
        data-testid="error-alert"
      />
    );
  }
  const loginWithOrgLink = `${location.origin}?org_id=${organization.id}&autosubmit=true#organization-lookup`;

  return (
    <div className="tw-ml-4" data-testid="organization-summary">
      <h4 className="tw-mb-5">Summary</h4>
      <ul className="preference-items tw-bg-palette-neutral-bg-weak tw-rounded-2xl tw-h-auto tw-p-4 tw-gap-2">
        <div className="organization-info tw-flex-wrap tw-ml-4">
          <div className="organization-info-item">
            <div className="tw-text-palette-neutral-text-weak"> Organization Name </div>
            <h5>{organization.displayName}</h5>
          </div>
          <div className="organization-info-item">
            <div className="tw-text-palette-neutral-text-weak"> Organization ID </div>
            <h5>
              {organizationId}
              <CopyTextToClipBoardButton
                text={organizationId}
                className="tw-ml-1"
                iconButtonProps={{
                  size: 'small',
                  style: { width: '18px', height: '18px', borderRadius: '4px' },
                }}
              />
            </h5>
          </div>
          {organization?.ssoOrgId && (
            <div className="organization-info-item">
              <div className="tw-text-palette-neutral-text-weak"> Organization SSO login </div>
              <h5>
                {loginWithOrgLink}
                <CopyTextToClipBoardButton
                  text={loginWithOrgLink}
                  className="tw-ml-1"
                  iconButtonProps={{
                    size: 'small',
                    style: { width: '18px', height: '18px', borderRadius: '4px' },
                  }}
                />
                <IconButton
                  size="small"
                  style={{ width: '18px', height: '18px', borderRadius: '4px' }}
                  className={'tw-ml-1'}
                  href={loginWithOrgLink}
                  iconName="ArrowTopRightOnSquareIconOutline"
                  title="Open"
                  as="a"
                  target="_blank"
                  clean
                />
              </h5>
            </div>
          )}
        </div>
        <Divider></Divider>
        <PreferenceItem
          title="Enable copilots in Workspace"
          description={
            <>
              <p>
                Workspace includes copilot features that use a Microsoft OpenAI as a third-party
                sub-processor. For these features to work, your database&apos;s schema is shared to
                assist with the creation of Cypher queries. No data is shared.
              </p>
            </>
          }
          action={
            <Switch
              data-testid="copilot-switch"
              onChange={handeCopilotToggle}
              checked={copilotEnabled}
              aria-label="Enable Copilot for workspace"
              disabled={!userCanEditOrgSettings}
            />
          }
        />
        {organization.capabilities?.[OrganizationCapabilityKeys.APP_MFA] && (
          <div>
            <PreferenceItem
              title="App MFA (Multi-Factor Authentication)"
              description={
                <>
                  <p>Enable MFA for all users in this Organization</p>
                </>
              }
              action={
                <Switch
                  data-testid="mfa-switch"
                  onChange={onToggleMfaChange}
                  checked={appMFARequired}
                  aria-label="Enable MFA for organization"
                  disabled={!userCanEditOrgSettings}
                />
              }
            />
          </div>
        )}
      </ul>

      {mutationError !== null && (
        <Alert
          description={mutationError.responseMessage}
          type="danger"
          className="tw-mt-2"
          data-testid="error-alert"
          closeable={true}
          onClose={() => setMutationError(null)}
        />
      )}
      {showConfirmMfaRequiredModal && (
        <ConfirmMfaModal
          onClose={() => setShowConfirmMfaRequiredModal(false)}
          onConfirm={handleAppMfaToggle}
          title={
            appMFARequired
              ? 'Disable organization Multi Factor Authentication (MFA)?'
              : 'Enable organization Multi Factor Authentication (MFA)?'
          }
          isLoading={isLoading}
          mfaEnabled={appMFARequired}
        />
      )}
    </div>
  );
};

export default SummaryPage;
