import React, { useCallback, useMemo, useState } from 'react';
import { formatDuration } from 'date-fns';

import Actions from 'actions';
import {
  Button,
  IconButton,
  Label,
  LoadingSpinner,
  Tip,
  StatusIndicator,
  createColumnHelper,
  useDefaultTable,
  StickyActionsDataGrid,
} from 'foundation';
import { Log, RemoteLog } from 'types/log';
import { useImmediateInterval } from 'utils/hooks';
import { getStatusColour, getStatusValue, mapRemoteLog, sortByRequestedAtDesc } from 'entities/log';
import { PermissionTip } from 'application/permission-tip';

import './logs.css';
import { usePermissions, useSession } from 'store';
import { Database } from 'entities/database';
import { Action } from 'types/user';
import { formatUpxDomain } from 'components/utils';

const LOG_REFRESH_PERIOD_SECS = 30;
const helper = createColumnHelper<Log>();

interface Props {
  database: Database;
}

const LogsSection = ({ database }: Props) => {
  const session = useSession();
  const { allow } = usePermissions();
  const allowDownloadLog = allow(
    Action.READ,
    `namespaces/${session.currentTenant}/databases/${database.DbId}/logs/*`
  );
  const buildQueryLogAnalyzerUrl = () => {
    const upxDomain = formatUpxDomain(process.env.ENVIRONMENT, process.env.NEO4J_DNS_DOMAIN);
    return `https://${upxDomain}/projects/${database.NamespaceId}/logs?instance=${database.DbId}`;
  };

  const [isLoading, setIsLoading] = useState(true);
  const [logs, setLogs] = useState([]);
  const columns = useMemo(
    () => [
      helper.accessor('start', {
        header: () => 'Log Start',
        minSize: 150,
      }),
      helper.accessor('type', {
        header: () => 'Type',
        minSize: 125,
      }),
      helper.accessor('status', {
        header: () => 'Status',
        minSize: 140,
        cell: cx => {
          return (
            <div className="n-label">
              <StatusIndicator type={getStatusColour(cx.getValue())} />
              <span>{getStatusValue(cx.getValue())}</span>
            </div>
          );
        },
      }),
      helper.accessor('timePeriod', {
        header: () => 'Time Period',
        cell: cx => {
          return (
            <Label className="time-period" color="default" fill="outlined">
              {cx.getValue()}
            </Label>
          );
        },
        minSize: 130,
      }),
      helper.accessor('expiresIn', {
        header: () => 'Expires In',
        cell: cx => {
          const expiresIn = cx.getValue();
          const shortExpiry =
            expiresIn.days === 0 ? '< 1 day' : formatDuration(expiresIn, { format: ['days'] });
          const fullExpiry = formatDuration(expiresIn, { format: ['days', 'hours', 'minutes'] });
          return (
            <Tip allowedPlacements={['top', 'bottom']}>
              <Tip.Trigger>
                <span aria-label={`Log expires in ${fullExpiry}`}>{shortExpiry}</span>
              </Tip.Trigger>
              <Tip.Content>{fullExpiry}</Tip.Content>
            </Tip>
          );
        },
      }),
      helper.display({
        id: 'logs-actions',
        cell: cx => {
          const log: Log = cx.row.original;
          if (!log.downloadLog) {
            return null;
          }
          const { dbid, logId, start } = log;
          const filename = `query-logs-${dbid}-${start.replace(':', '').replace(' ', '_')}.log`;

          return (
            <PermissionTip hasPermission={allowDownloadLog}>
              <IconButton
                data-testid="download-logs"
                className="download-logs"
                iconName="ArrowDownTrayIconOutline"
                aria-label="Download logs"
                title="Download logs"
                onClick={() => Actions.logs.downloadLog(dbid, logId, filename)}
                download={!allowDownloadLog}
                clean
              />
            </PermissionTip>
          );
        },
        meta: {
          isStickyAction: true,
        },
        size: 80,
      }),
    ],
    []
  );

  const callback = useCallback(() => {
    Actions.logs.fetchLogs(database.DbId).then((remoteLogs: RemoteLog[]) => {
      const mappedLogs: Log[] = remoteLogs.map(mapRemoteLog);
      mappedLogs.sort(sortByRequestedAtDesc);
      setLogs(mappedLogs);
      setIsLoading(false);
    });
  }, [database]);

  useImmediateInterval(callback, LOG_REFRESH_PERIOD_SECS * 1000);

  const table = useDefaultTable({
    data: logs,
    columns,
  });

  return (
    <div className="request-logs-tab">
      {isLoading && (
        <div className="tw-w-full tw-flex tw-flex-col tw-items-center">
          <LoadingSpinner size="large" />
          <span>Loading...</span>
        </div>
      )}
      {!isLoading && (
        <>
          <div className="tw-w-full tw-flex tw-items-top tw-justify-between">
            <div className="n-body-medium tw-text-palette-neutral-text-weaker">
              <p>
                You can request both query logs and security logs in the new experience of Aura.
              </p>
              {logs && logs.length > 0 && (
                <p>
                  Any previously requested logs will appear here for up to 7 days, at which point
                  they will expire and be removed.
                </p>
              )}
              {logs && logs.length > 0 && (
                <p className="tw-my-2">All times presented here are in UTC.</p>
              )}
            </div>
            <div className="tw-flex tw-flex-row tw-gap-4 tw-justify-end">
              <Button
                fill="outlined"
                color="neutral"
                iconName="ArrowTopRightOnSquareIconOutline"
                href={buildQueryLogAnalyzerUrl()}
                target="_blank"
                rel="noreferrer"
              >
                Request log
              </Button>
            </div>
          </div>
          {logs && logs.length > 0 && <StickyActionsDataGrid tableInstance={table} />}
        </>
      )}
    </div>
  );
};

export default LogsSection;
