import { Button, Dialog } from '@neo4j-ndl/react';
import React from 'react';

interface ModalProps {
  onClose?: () => void;
  onConfirm?: () => void;
  title: string;
  isLoading: boolean;
  mfaEnabled: boolean;
}

const MFA_DISABLE_MODAL_DESCR =
  'Neo4j does not recommend turning off MFA because it will significantly reduce your account security.';

const MFA_ENABLE_MODAL_DESCR =
  'Once enabled, you will be required to login with a One Time Passcode which can be set up using Authy, Google Authenticator, Auth0 Guardian or Microsoft Authenticator.';

export const ConfirmMfaModal = (props: ModalProps) => {
  const { onClose, onConfirm, title, mfaEnabled } = props;

  const disableContent = () => {
    return (
      <div className="flex">
        <p className="me-2 font-bold">IMPORTANT:</p>
        <p>{MFA_DISABLE_MODAL_DESCR}</p>
      </div>
    );
  };

  return (
    <Dialog open onClose={onClose} size="unset" modalProps={{ className: 'max-w-[1000px]' }}>
      <Dialog.Header>{title}</Dialog.Header>
      <Dialog.Content>{mfaEnabled ? disableContent() : MFA_ENABLE_MODAL_DESCR}</Dialog.Content>
      <Dialog.Actions>
        <Button fill="outlined" color="neutral" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={onConfirm}>Confirm</Button>
      </Dialog.Actions>
    </Dialog>
  );
};
