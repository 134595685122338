import React, { useEffect, useState } from 'react';
import UsersResource from 'remote/resources/users';
import UserDetailsResource from 'remote/resources/user-details';
import { Button, Alert, Dialog } from 'foundation';
import { MFAStatus, User } from 'types/user';

interface Props {
  user: User;
  onClose: () => any;
}

export const UpdateAppMFAModal = ({ user, onClose }: Props) => {
  const [error, setError] = useState<Error>(null);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [appMFAEnabled, setAppMFAEnabled] = useState(false);

  useEffect(() => {
    UserDetailsResource.get().then(userDetails => {
      setAppMFAEnabled(userDetails.MFAStatus === MFAStatus.ENABLED);
    });
  }, []);

  const handleSubmit = async () => {
    setError(null);
    setLoading(true);
    try {
      await UsersResource.updateAppMFA(user.UserId, !appMFAEnabled);
      setSuccess(true);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
      onClose();
    }
  };

  return (
    <Dialog open onClose={onClose}>
      <Dialog.Header data-testid="user-app-mfa-header">Are you sure?</Dialog.Header>
      <Dialog.Content data-testid="user-app-mfa-content">
        <Dialog.Description>
          This will {appMFAEnabled ? 'disable' : 'enable'} App MFA for user {user.Email}.
        </Dialog.Description>
        {error && (
          <Alert type="danger" className="tw-mt-2">
            {String(error)}
          </Alert>
        )}
        {success && (
          <Alert type="success" data-testid="user-app-mfa-success" className="tw-mt-2">
            App MFA was successfully {appMFAEnabled ? 'disabled' : 'enabled'}.
          </Alert>
        )}
      </Dialog.Content>
      <Dialog.Actions>
        <Button data-testid="user-app-mfa-cancel" onClick={onClose} fill="outlined" color="neutral">
          Cancel
        </Button>

        <Button
          data-testid="submit-app-mfa-button"
          color={appMFAEnabled ? 'danger' : 'primary'}
          onClick={handleSubmit}
          disabled={loading || success}
        >
          {appMFAEnabled ? 'Disable' : 'Enable'}
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};
