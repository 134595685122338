import { SyntheticEvent } from 'react';
import track, { useTracking } from 'react-tracking';
import { Database, DatabaseStatus } from 'entities/database';
import globals from 'browser/globals';
import { formatUpxDomain } from 'components/utils';

export interface OpenWithMigrationAssistantChildArgs {
  onClick: (e: SyntheticEvent) => void;
  disabled: boolean;
}
export interface OpenWithMigrationAssistantProps {
  database: Database;
  children: (args: OpenWithMigrationAssistantChildArgs) => JSX.Element;
}

const openInNewTab = (url: string): void => {
  const newWindow = globals.window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

const buildMigrationAssistantURL = (database: Database) => {
  const upxDomain = formatUpxDomain(process.env.ENVIRONMENT, process.env.NEO4J_DNS_DOMAIN);
  return `https://${upxDomain}/projects/${database.NamespaceId}/instances/${database.DbId}/migration-readiness-report/`;
};

export const handleMigrationAssistantClick = (database: Database) => {
  const migrationAssistantUrl = buildMigrationAssistantURL(database);
  openInNewTab(migrationAssistantUrl);
};

export const isMigrationAssistantAccessible = (database: Database) => {
  switch (database.DatabaseStatus) {
    case DatabaseStatus.CREATING:
    case DatabaseStatus.DESTROYED:
    case DatabaseStatus.DESTROYING:
      return false;
    default:
      return true;
  }
};

export const OpenWithMigrationAssistant = track()(
  ({ database, children }: OpenWithMigrationAssistantProps) => {
    const tracking = useTracking();
    return children({
      onClick: () => {
        tracking.trackEvent({
          action: 'open_db_migration_assistant',
        });
        handleMigrationAssistantClick(database);
      },
      disabled: !isMigrationAssistantAccessible(database),
    });
  }
);
