import ApiClient from 'remote/api-client';
import { User } from 'types/user';

const search = async (query: string): Promise<User[]> =>
  ApiClient.post('/users/search')
    .issue({ Query: query })
    .promise()
    .then(value => {
      if (Array.isArray(value)) {
        return value.filter((v: any): boolean => v !== null && v !== undefined);
      }
      return value ? [value] : [];
    });

const updateDefaultTenant = async (userId: string, tenantId: string) =>
  ApiClient.patch(`/users/${encodeURIComponent(userId)}`)
    .issue({ DefaultNamespace: tenantId })
    .ignoreAllErrors()
    .promise();

const resetAuthenticationMethod = async (userId: string): Promise<any> =>
  ApiClient.delete(`/users/${encodeURIComponent(userId)}/subject-id`).promise();

const terminateUser = async (userId: string): Promise<any> =>
  ApiClient.post(`/users/${encodeURIComponent(userId)}/terminate`).promise();

const unterminateUser = async (userId: string): Promise<any> =>
  ApiClient.post(`/users/${encodeURIComponent(userId)}/unterminate`).promise();

const updateEmailMFA = async (userId: string, value: boolean): Promise<any> =>
  ApiClient.post(`/users/${userId}/email-mfa`)
    .issue({ Enabled: value })
    .promise();

const updateAppMFA = async (userId: string, value: boolean): Promise<any> =>
  ApiClient.post(`/users/${userId}/mfa`)
    .issue({ Enabled: value })
    .promise();

const getTenants = async (userId: string): Promise<any> =>
  ApiClient.get(`/users/${encodeURIComponent(userId)}/namespaces`).promise();

/**
 * Links the users logins in console (no auth0)
 *
 * @param link_to_token The token of the account to be linked to
 */
const linkLogins = async (newUserToken: string, linkToToken: string): Promise<any> => {
  return ApiClient.post('/users/link')
    .bearerAuth(linkToToken)
    .issue({ link_to_token: newUserToken })
    .promise();
};

export default {
  search,
  resetAuthenticationMethod,
  terminateUser,
  unterminateUser,
  updateDefaultTenant,
  getTenants,
  linkLogins,
  updateEmailMFA,
  updateAppMFA,
};
