import React, { useEffect, useState, SyntheticEvent } from 'react';

import DatabaseName from 'application/db-name';
import ConfirmModal, { ConfirmAction } from 'foundation/confirm-modal';
import { Tip } from '@neo4j-ndl/react';
import globals from 'browser/globals';
import { Alert } from 'foundation';
import { Database } from 'entities/database';
import { CustomEndpoint } from 'types/custom-endpoints';
import { customEndpointURI } from 'entities/custom-endpoints';

interface Props {
  database: Database;
  loading: boolean;
  open: boolean;
  onConfirm: (e: SyntheticEvent) => void;
  onCancel: () => void;
  error?: any;
  errorHeader?: any;
  gdsAvailable: boolean;
  customEndpoints: CustomEndpoint[];
}

const DestroyDatabaseModal = ({
  database,
  loading,
  open,
  onConfirm,
  onCancel,
  error = undefined,
  errorHeader = undefined,
  gdsAvailable,
  customEndpoints,
}: Props) => {
  const [tipOpen, setTipOpen] = useState(false);
  useEffect(() => {
    if (tipOpen) {
      setTimeout(() => {
        setTipOpen(false);
      }, 2000);
    }
  }, [tipOpen]);

  return (
    <ConfirmModal
      title={
        <div className="tw-truncate">
          Are you sure you want to destroy <br />
          <Tip isOpen={tipOpen} type="toggletip" allowedPlacements={['right', 'left']}>
            <Tip.Trigger className="tw-cursor-text">
              <i
                onClick={() => {
                  globals.window.navigator.clipboard.writeText(database.Name);
                  if (!tipOpen) {
                    setTipOpen(true);
                  }
                }}
                aria-aria-label="Copy text"
              >
                <DatabaseName Name={database.Name} DbId={database.DbId} />
              </i>
            </Tip.Trigger>
            <Tip.Content isPortaled={false}>Copied to clipboard</Tip.Content>
          </Tip>{' '}
          ?
        </div>
      }
      content={
        <p>This is irreversible. We will destroy your instance and all associated snapshots.</p>
      }
      banner={
        <>
          {gdsAvailable === true && (
            <div>
              <Alert
                type="warning"
                description="Data Science sessions created from this instance will not be able to
                write back results while the database is deleted. We recommend that you terminate
                all connected Data Science sessions before deleting the instance."
              />
            </div>
          )}
          {customEndpoints.length !== 0 && (
            <div>
              <Alert
                type="warning"
                description={
                  <span>
                    This instance is assigned to the following custom endpoints:
                    {customEndpoints.map((endpoint, i) => (
                      <>
                        {i === 0 && <br />}
                        <i key={endpoint.id}>{customEndpointURI(endpoint)}</i>
                        <br />
                      </>
                    ))}
                    By deleting this instance, the custom endpoints will become disconnected until
                    another instance is assigned.
                  </span>
                }
              />
            </div>
          )}
        </>
      }
      negative
      action={ConfirmAction.DESTROY}
      buttonTextLoading="Destroying"
      confirmText={database.Name}
      loading={loading}
      open={open}
      onConfirm={onConfirm}
      onCancel={onCancel}
      error={error}
      errorHeader={errorHeader}
    />
  );
};

export default DestroyDatabaseModal;
