import Auth from 'pages/auth';
import NotFoundPage from 'pages/not-found';
import { match, page } from './helpers';

import { publicRoutes } from './public';
import { privateRoutes } from './private';
import { SessionStore } from 'state';
import { useLocation, useSession } from 'store';
import React from 'react';
import { parse } from 'query-string';
import Neo4jLogo from 'ui/icons/aura-colored.svg';
import { Button } from 'components/foundation';
import Logout from 'pages/auth/logout';

const routes = [
  {
    requiresLogin: true,
    requiresTsAndCs: false,
    description: 'Logout Page',
    routeMatcher: match(/logout/),
    minimalView: true,
    render: () => <Logout />,
  },
  ...publicRoutes,
  ...privateRoutes,
  {
    description: 'Login Page',
    requiresLogin: false,
    stateMatcher: () => !SessionStore.state.loggedIn,
    minimalView: true,
    render: () => <Auth />,
  },
  {
    description: 'Not Found Page',
    requiresLogin: false,
    render: () => <NotFoundPage />,
  },
];

const Auth0Error = ({ search }: { search: Record<string, any> }) => {
  return (
    <div className="tw-flex tw-justify-center">
      <div className="tw-mt-24 tw-p-4 tw-flex tw-flex-col tw-gap-2 tw-rounded-md tw-bg-palette-neutral-bg-weak tw-drop-shadow-md">
        <Neo4jLogo />
        <h4 className="tw-mt-8">Authentication error</h4>
        <p>{search.error}</p>
        <p>{search.error_description}</p>
        <div>
          <Button href="/#">Back to login</Button>
        </div>
      </div>
    </div>
  );
};

const Router = (currentPage, Template) => {
  const session = useSession();
  const location = useLocation();
  const search = parse(location.search);

  if (search.error && search.error_description) {
    return <Auth0Error search={search} />;
  }

  for (let i = 0; i < routes.length; i++) {
    const route = routes[i];
    const tryRenderPage = page(route);

    const rendered = tryRenderPage(currentPage, session);

    if (rendered) {
      return (route as any).minimalView ? rendered : <Template>{rendered}</Template>;
    }
  }

  throw new Error('No route matched, this should be impossible!');
};

export default Router;
