import { saveAs } from 'file-saver';
import LogsResource from 'remote/resources/logs';
import { RemoteLog } from 'types/log';

const fetchLogs = async (dbid: string): Promise<RemoteLog[]> => {
  const { data } = await LogsResource.listLogs(dbid);
  return data;
};

const downloadLog = async (dbid: string, logId: string, filename: string): Promise<void> => {
  const { url } = await LogsResource.getDownloadLink(dbid, logId);
  // The filename given here doesn't take - limitations of the saveAs
  // library, which is falling back to a virtual anchor element to
  // avoid being blocked by CORS.
  saveAs(url, filename);
};

export default { fetchLogs, downloadLog };
